<div class="student_details_section d-flex">
  <div class="left_bg w-25 p-4">
    <div class="row-filter mb-3">

    </div>
  </div>
  <div class="p-4 w-75">
    <div class="text-80">
      <div class="p-1 pr-2 pl-2 text-100">
        <div class="row white p-2">
          <div class="col-sm-3">
            <p class="m-0 font-weight-500"></p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500"></p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500"></p>
          </div>
          <div class="col-sm-2">
            <p class="m-0 font-weight-500"></p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center"></p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500 text-center"></p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'Mid Term' | translate }}</p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'End Term' | translate }}</p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500 text-center"></p>
          </div>

        </div>
        <div class="row white p-2">
          <div class="col-sm-2 d-flex align-items-start">
            <!-- <input type="checkbox" class="mr-2 mt-3px"> -->
            <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
          </div>
          <div class="col-sm-2">
            <p class="m-0 font-weight-500">{{ 'Group' | translate }}</p>
          </div>
          <div class="col-sm-2">
            <p class="m-0 font-weight-500">{{ 'Codes' | translate }}</p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500">{{ 'Session' | translate }}</p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'Paper Type' | translate }}</p>
          </div>
          <div class="col-sm-1">
            <p class="m-0 font-weight-500 text-center">{{ 'Semester' | translate }}</p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
          </div>
          <div class="col-sm-1 p-0">
            <p class="m-0 font-weight-500 text-center">{{ 'Status' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="p-1 pr-2 pl-2">
        <ng-container *ngFor="let paperData of papers; index as i;">
          <div class="row item-row text-80 p-2" (click)="redirectTo(paperData.paper)">
            <div class="col-sm-2 d-flex align-items-start">
              <input type="checkbox" class="mr-2 mt-3px">
              <p class="m-0 font-weight-500">{{paperData?.paper?.name}}</p>
            </div>
            <div class="col-sm-2">
              <p class="m-0 font-weight-500">{{paperData?.paper?.groupCode}}</p>
            </div>
            <div class="col-sm-2">
              <p class="m-0 font-weight-500">{{paperData?.paper?.code}}</p>
            </div>
            <div class="col-sm-1">
              <p class="m-0 font-weight-500">{{paperData?.paper?.sessionName}}</p>
            </div>
            <div class="col-sm-1 p-0">
              <p
                class="m-0 p-1 badge text-white font-weight-700 text-center paper_{{env.paperType[paperData?.paper?.paperType]}}">
                {{env.paperType[paperData?.paper?.paperType]}}

              </p>
            </div>
            <div class="col-sm-1">
              <p class="m-0 font-weight-500 text-center">{{paperData?.paper?.semester}}</p>
            </div>
            <div class="col-sm-1 p-0">
              <p class="m-0 font-weight-500 text-center">{{paperData?.paper?.midTermAllocatedMarks}}</p>
            </div>
            <div class="col-sm-1 p-0">
              <p class="m-0 font-weight-500 text-center">{{paperData?.alocatedEndTermMarks}}</p>
            </div>
            <div class="col-sm-1 p-0">
              <p class="m-0 font-weight-500 text-center">
                <!-- <span class="badge badge-primary"
                  *ngIf="(paperData.alocatedEndTermMarks>0 && !paperData.endTermMarksSubmitedOn) || (paperData.allowSubmitMidTermMarks && !paperData.midTermMarksSubmitedOn) || (paperData.alocatedEndTermMarks>0 && paperData.allowSubmitMidTermMarks && !paperData.midTermMarksSubmitedOn)">Pending</span> -->
                <span class="badge badge-success"
                  *ngIf="( paperData.endTermMarksSubmitedOn && paperData.allowSubmitMidTermMarks && paperData.allowSubmitEndTermMarks && paperData.midTermMarksSubmitedOn) || (paperData.allowSubmitMidTermMarks && !paperData.allowSubmitEndTermMarks && paperData.midTermMarksSubmitedOn ) || (!paperData.allowSubmitMidTermMarks && paperData.allowSubmitEndTermMarks && paperData.endTermMarksSubmitedOn);else other_content">Done</span>

                <ng-template #other_content><span class="badge badge-primary">Pending</span></ng-template>
              </p>

            </div>
          </div>
        </ng-container>
      </div>

    </div>

  </div>
</div>

<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-4">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterKeyword" class="form-control" type="text" placeholder="Paper Name, Paper Code">
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSemester" class="form-control">
                <option value="0">{{ 'Select semester' | translate }}</option>
                <option *ngFor="let semester of showSemesters" [value]="semester">{{ 'Semester' | translate }}
                    {{semester}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterLevel" class="form-control">
                <option value="0">{{ 'Academic level' | translate }}</option>
                <option *ngFor="let academiclevel of env.academicLevel | keyvalue" [value]="academiclevel.key">
                    {{academiclevel.value}}</option>
            </select>
        </div>
        <div class="row-filter mb-3" *ngIf="disciplines">
            <angular2-multiselect class="form-control" [data]="disciplines" [settings]="disciplineSearchSettings"
                [(ngModel)]="filterDiscipline">
                <c-item>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-item>
                <c-badge>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-badge>
            </angular2-multiselect>
        </div>
        <div class="text-right">
            <button (click)="paperFilter()"
                class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' | translate }} <em
                    aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-4 w-75">
        <!-- <div *ngIf="totalCount>0;else noResult" class="text-80">
            <div class="p-1 pr-2 pl-2 text-100">
                <div class="row white p-2">
                    <div class="col-sm-3">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Mid Term' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'End Term' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                </div>
                <div class="row white p-2">
                    <div class="col-sm-3 d-flex align-items-start">
                        <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Group' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Codes' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Session' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Paper Type' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center">{{ 'Semester' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
                    </div>
                    <div class="col-sm-1 d-flex align-items-start justify-content-between">
                        <p class="m-0 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
                        <em class="fa fa-pencil ml-2 mt-3px"></em>
                    </div>
                </div>
            </div>
            <ng-template #noResult>No Data Found</ng-template>
            <div class="p-1 pr-2 pl-2">
                <div *ngFor="let paper of papers; index as i;" class="row item-row text-80 p-2">

                    <div class="col-sm-3 d-flex align-items-start">
                        <p class="m-0 font-weight-500">{{paper.name}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{paper.groupCode}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{paper.code}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{paper.sessionName}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p
                            class="m-0 p-1 badge text-white font-weight-700 text-center paper_{{env.paperType[paper.paperType]}}">
                            {{env.paperType[paper.paperType]}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center">{{paper.semester}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{paper.midTermAllocatedMarks}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{paper.endTermAllocatedMarks}}</p>
                    </div>
                    <div class="col-sm-1 d-flex align-items-start justify-content-between">
                        <p class="m-0 font-weight-500 text-center">{{paper.credit}}</p>
                        <em (click)="paperConfigure(paper)" class="fa fa-pencil ml-2 mt-3px"></em>
                    </div>
                </div>
            </div>
        </div> -->
        <ng-template #noResult>No record found</ng-template>
        <div *ngIf="totalCount>0;else noResult" class="d-flex p-1">
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Group' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Code' | translate }}</p>
            </div>
            <div class="col-sm-5">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Name' | translate }}</p>
            </div>
            <div class="col-sm-1">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center">{{ 'Full Marks' | translate }}</p>
            </div>
        </div>
        <div *ngFor="let semester of papers|keyvalue">
            <p class="m-0 h6 p-1 pl-3 pr-3"><strong>{{ 'Semester' | translate }} : {{semester.key}}</strong></p>
            <ng-container *ngFor="let paper of semester.value">
                <div class="d-flex item-row p-1" (click)="paperConfigure(paper)">
                    <div class="col-sm-2">
                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">
                            <span *ngIf="paper.evaluators.length>0">
                                <i class="fa fa-check-circle text-success text-22"></i>
                            </span>
                            <span *ngIf="paper.evaluators.length==0"><i class="fa fa-circle-thin text-muted"></i>
                            </span> {{paper.groupCode}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{paper.code}}</p>
                    </div>
                    <div class="col-sm-5">
                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1"> {{paper.name}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{paper.credit}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{paper.fullMarks}}</p>
                    </div>
                </div>
                <div *ngIf="paper.evaluators.length>0" class="d-flex justify-content-between p-1 text-80 white border">
                    <div class="p-2 w-50">
                        <div class="h5 m-0 p-2 mb-1 grey lighten-3">Examiners</div>
                        <ng-container *ngFor="let evaluator of paper.evaluators">
                            <div *ngIf="evaluator.type==2" class="p-1">{{evaluator.examinerName}}</div>
                        </ng-container>
                    </div>
                    <div class="p-2 w-50">
                        <div class="h5 m-0 p-2 mb-1 grey lighten-3">Reviewers</div>
                        <ng-container *ngFor="let evaluator of paper.evaluators">
                            <div *ngIf="evaluator.type==1" class="p-1">{{evaluator.examinerName}}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

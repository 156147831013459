import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExamService = /** @class */ (function () {
    function ExamService(_http) {
        this._http = _http;
        this.onExamsUpdated = new Subject();
    }
    ExamService.prototype.getExam = function (id) {
        return this._http.get(config.apiEndPointBase + "examination/" + id);
    };
    ExamService.prototype.save = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "examination", data, {})
                .pipe(map(function (response) {
                return response;
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "examination", data, {})
                .pipe(map(function (response) {
                return response;
            }));
        }
    };
    ExamService.prototype.getEmployees = function (disciplineId) {
        return this._http.get(config.apiEndPointBase + "discipline/" + disciplineId + "/employees");
    };
    ExamService.prototype.linkUnlinkEmployee = function (data, type) {
        return this._http
            .put(config.apiEndPointBase + "discipline/" + data.disciplineId + "/employee/" + type + "/" + data.employeeId, {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    ExamService.prototype.downloadSubmitMarks = function (examinationId, paperId, evaluatorId, mode, term) {
        if (term == "mid") {
            return this._http.get(config.apiEndPointBase + "examination/download-submited-mid-term-marks/" + examinationId + "/" + paperId + "/" + evaluatorId + "/" + mode);
        }
        else {
            return this._http.get(config.apiEndPointBase + "examination/download-submited-end-term-marks/" + examinationId + "/" + paperId + "/" + evaluatorId + "/" + mode);
        }
    };
    ExamService.prototype.submitMarks = function (data) {
        return this._http
            .post(config.apiEndPointBase + "examination/submit-marks", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    ExamService.prototype.setEvaluators = function (data) {
        return this._http
            .post(config.apiEndPointBase + "action/set-evaluators", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    ExamService.prototype.sendSubmitMarksEmail = function (examinationId, paperId, mode, term) {
        if (term == "mid") {
            return this._http.get(config.apiEndPointBase + "examination/send-submited-mid-term-marks/" + examinationId + "/" + paperId + "/" + mode + "?sendEmail=true");
        }
        else {
            return this._http.get(config.apiEndPointBase + "examination/send-submited-end-term-marks/" + examinationId + "/" + paperId + "/" + mode + "?sendEmail=true");
        }
    };
    ExamService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExamService_Factory() { return new ExamService(i0.ɵɵinject(i1.HttpClient)); }, token: ExamService, providedIn: "root" });
    return ExamService;
}());
export { ExamService };
